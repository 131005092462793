import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "./Auth";
import { auth } from "./firebase.js";

const MainNav = () => {
  let navLinks = [];
  let signOut = null;
  let headerClass = "header";

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    signOut = (
      <button className="logout" onClick={() => auth.signOut()}>
        Log Out
      </button>
    );
    headerClass = "header space-between";
    navLinks.push(
      <NavLink
        to="/log"
        activeClassName="selected"
        className="nav-item"
        key="log"
      >
        <div className="ni-icon">
          <svg width="70" height="60" viewBox="0 0 70 60">
            <g>
              <path
                className="st0"
                d="M144.08,30.34V5.57c0-1.33-0.71-2.57-1.87-3.23l-21.45-12.38c-1.15-0.67-2.58-0.67-3.73,0L95.59,2.34
     c-1.15,0.67-1.87,1.9-1.87,3.23v24.77c0,1.33,0.71,2.57,1.87,3.23l21.45,12.38c1.16,0.67,2.58,0.67,3.73,0l21.45-12.38
     C143.37,32.9,144.08,31.67,144.08,30.34z"
              />
              <polygon
                className="st1"
                points="122.9,11.82 133.88,12.44 125.38,19.43 128.19,30.06 118.92,24.14 109.67,30.09 112.44,19.45 
     103.92,12.49 114.9,11.84 118.88,1.58 	"
              />
            </g>
            <g>
              <path
                className="st1"
                d="M32.92,19.55c0.29,0.41,0.64,0.78,1.04,1.07c0.41-0.3,0.76-0.66,1.04-1.07c0.24-0.33,0.43-0.69,0.56-1.07
     c0.12-0.35,0.2-0.71,0.21-1.07c0.02-0.37-0.06-0.74-0.23-1.07h-3.16c-0.17,0.33-0.25,0.7-0.23,1.07c0.02,0.37,0.09,0.73,0.21,1.07
     C32.49,18.86,32.68,19.22,32.92,19.55z"
              />
              <path
                className="st1"
                d="M35.01,49.85c-0.29-0.41-0.64-0.78-1.04-1.07c-0.41,0.3-0.76,0.66-1.04,1.07c-0.24,0.33-0.43,0.69-0.56,1.07
     c-0.12,0.35-0.2,0.71-0.21,1.07c-0.02,0.37,0.06,0.74,0.23,1.07h3.16c0.17-0.33,0.25-0.7,0.23-1.07c-0.02-0.37-0.09-0.73-0.21-1.07
     C35.43,50.54,35.25,50.18,35.01,49.85z"
              />
              <path
                className="st1"
                d="M18.81,33.66c-0.33-0.24-0.69-0.43-1.07-0.56c-0.35-0.12-0.71-0.2-1.07-0.21c-0.37-0.02-0.74,0.06-1.07,0.23
     v3.16c0.33,0.17,0.7,0.25,1.07,0.23c0.37-0.02,0.73-0.09,1.07-0.21c0.38-0.13,0.75-0.32,1.07-0.56c0.41-0.29,0.78-0.64,1.07-1.04
     C19.58,34.29,19.22,33.94,18.81,33.66z"
              />
              <path
                className="st2"
                d="M46.32,27.55l-11.2,5.29c-1.03-0.64-2.38-0.33-3.02,0.7c-0.64,1.03-0.33,2.38,0.7,3.02
     c1.03,0.64,2.38,0.33,3.02-0.7c0.2-0.32,0.31-0.68,0.33-1.06l10.73-6.2c0.28-0.16,0.38-0.53,0.22-0.81
     C46.93,27.52,46.6,27.42,46.32,27.55L46.32,27.55z"
              />
              <path
                className="st3"
                d="M35.52,11.02v-4.3l3.64-1.48c0.24-0.1,0.4-0.33,0.4-0.59V2.13c0-0.35-0.28-0.63-0.63-0.63c0,0,0,0,0,0H29
     c-0.35,0-0.63,0.28-0.63,0.63v2.53c0,0.26,0.16,0.49,0.4,0.59l3.64,1.48v4.3 M51.82,19.08l3.11-3.11l1.5,1.5
     c0.34,0.34,0.9,0.34,1.24,0l1.82-1.82c0.46-0.45,0.47-1.2,0.01-1.66c-0.06-0.06-0.13-0.12-0.21-0.17
     c-1.77-1.17-3.29-2.69-4.46-4.46c-0.35-0.55-1.07-0.71-1.62-0.37c-0.08,0.05-0.15,0.11-0.21,0.17l-1.82,1.82
     c-0.34,0.34-0.34,0.9,0,1.24l1.5,1.5l-3.11,3.11"
              />
              <g>
                <path
                  className="st3"
                  d="M34.1,12.42c12.21,0,22.14,9.93,22.14,22.14S46.3,56.69,34.1,56.69s-22.14-9.93-22.14-22.14
         S21.89,12.42,34.1,12.42 M34.1,10.61c-13.22,0-23.95,10.72-23.95,23.95S20.87,58.5,34.1,58.5s23.95-10.72,23.95-23.95
         S47.32,10.61,34.1,10.61L34.1,10.61z"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="ni-label">Log</div>
      </NavLink>
    );
    navLinks.push(
      <NavLink
        to="/achievements"
        activeClassName="selected"
        className="nav-item"
        key="achievements"
      >
        <div className="ni-icon">
          <svg width="70" height="60" viewBox="0 0 70 60">
            <g>
              <path
                className="st0"
                d="M60.08,42.34V17.57c0-1.33-0.71-2.57-1.87-3.23L36.77,1.96c-1.15-0.67-2.58-0.67-3.73,0L11.59,14.34
c-1.15,0.67-1.87,1.9-1.87,3.23v24.77c0,1.33,0.71,2.57,1.87,3.23l21.45,12.38c1.16,0.67,2.58,0.67,3.73,0l21.45-12.38
C59.37,44.9,60.08,43.67,60.08,42.34z"
              />
              <polygon
                className="st1"
                points="38.9,23.82 49.88,24.44 41.38,31.43 44.19,42.06 34.92,36.14 25.67,42.09 28.44,31.45 19.92,24.49 
30.9,23.84 34.88,13.58 	"
              />
            </g>
          </svg>
        </div>
        <div className="ni-label">Achievements</div>
      </NavLink>
    );
  } else {
    navLinks.push(
      <NavLink
        to="/login"
        activeClassName="selected"
        className="nav-item"
        key="login"
      >
        <div className="ni-icon">
          {" "}
          <svg width="70" height="60" viewBox="0 0 70 60">
            <g>
              <g>
                <g>
                  <path
                    className="st0"
                    d="M60.04,12.92C45.38,11.06,36.98,2.53,36.62,2.17c-0.48-0.48-1.14-0.72-1.8-0.66
				c-0.54,0.06-1.02,0.3-1.38,0.66C33.37,2.23,25.03,11,10.01,12.92c-1.14,0.12-2.04,1.26-1.98,2.4c2.28,31.71,25.1,42.58,26.06,43
				c0.3,0.12,0.6,0.18,0.9,0.18c0.3,0,0.6-0.06,0.9-0.18c0.96-0.42,23.78-11.29,26.06-43C62.08,14.12,61.24,13.04,60.04,12.92z
				 M35.06,53.82C31.09,51.59,15.18,41.2,12.6,16.94c11.71-1.98,19.34-7.57,22.46-10.27c3.18,2.76,10.87,8.35,22.4,10.33
				C54.93,41.38,39.14,51.59,35.06,53.82z"
                  />
                  <path
                    className="st1"
                    d="M28.44,21.45v3.9H28.2c-1.52,0-2.76,1.24-2.76,2.76V37c0,1.52,1.24,2.76,2.76,2.76h13.69
				c1.52,0,2.76-1.24,2.76-2.76v-8.89c0-1.52-1.24-2.76-2.76-2.76h-0.24v-3.9c0-3.6-2.94-6.61-6.61-6.61S28.44,17.85,28.44,21.45z
				 M39.14,29.61c0.48,0.48,0.48,1.2,0,1.68l-3.96,3.96c-0.24,0.24-0.54,0.36-0.84,0.36c-0.3,0-0.6-0.12-0.84-0.36l-2.52-2.52
				c-0.48-0.48-0.48-1.2,0-1.68s1.2-0.48,1.68,0l1.68,1.68l3.18-3.18C37.94,29.13,38.66,29.13,39.14,29.61z M38.04,21.52v3.83h-6.01
				v-3.9c0-1.9,1.73-3.42,3.67-2.99C37.1,18.77,38.04,20.09,38.04,21.52z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div className="ni-label">Login</div>
      </NavLink>
    );
    navLinks.push(
      <a href="/#join"
        className="nav-item"
        key="join"
      >
        <div className="ni-icon">
          {" "}
          <svg width="70" height="60" viewBox="0 0 70 60">
            <g>
              <g>
                <g>
                  <g>
                    <path
                      className="st1"
                      d="M56.55,12.97l-1.52,0l0,1.52c0,0.63-0.37,1.24-0.97,1.44c-1,0.33-1.93-0.42-1.93-1.37v-1.59l-1.45,0
					c-0.63,0-1.24-0.37-1.44-0.97c-0.33-1,0.42-1.93,1.37-1.93h1.52l0-1.45c0-0.63,0.37-1.24,0.97-1.44c1-0.33,1.93,0.42,1.93,1.37
					v1.52h1.59c0.95,0,1.7,0.93,1.37,1.93C57.79,12.61,57.18,12.97,56.55,12.97z"
                    />
                  </g>
                </g>
              </g>
              <path
                className="st0"
                d="M40.08,34.29c2.07-1.65,3.42-4.28,3.42-7.24c0-5-3.81-9.05-8.5-9.05c-4.69,0-8.5,4.05-8.5,9.05
		c0,2.97,1.35,5.59,3.42,7.24c1.42,1.13,3.17,1.8,5.08,1.8C36.91,36.1,38.66,35.42,40.08,34.29z"
              />
              <path
                className="st0"
                d="M51.5,51c0-7.81-4.8-14.43-11.42-16.71c-1.42,1.13-3.17,1.8-5.08,1.8c-1.91,0-3.66-0.68-5.08-1.8
		C23.3,36.57,18.5,43.19,18.5,51"
              />
              <g>
                <path
                  className="st0"
                  d="M59.35,18.33c1.92-1.65,3.15-4.09,3.15-6.83c0-4.97-4.03-9-9-9c-2.73,0-5.18,1.22-6.83,3.15"
                />
                <path
                  className="st0"
                  d="M53.5,20.5c-4.97,0-9-4.03-9-9c0-2.24,0.82-4.28,2.17-5.85C43.14,3.95,39.18,3,35,3C20.09,3,8,15.09,8,30
			s12.09,27,27,27s27-12.09,27-27c0-4.18-0.95-8.14-2.65-11.67C57.78,19.68,55.74,20.5,53.5,20.5z"
                />
                <path
                  className="st0"
                  d="M46.67,5.65c-1.35,1.57-2.17,3.62-2.17,5.85c0,4.97,4.03,9,9,9c2.24,0,4.28-0.82,5.85-2.17"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="ni-label">Join</div>
      </a>
    );
  }

  navLinks.push(
    <NavLink
      to="/leaderboard"
      activeClassName="selected"
      className="nav-item"
      key="leaderboard"
    >
      <div className="ni-icon">
        <svg width="70" height="60" viewBox="0 0 70 60">
          <g>
            <path
              className="st0"
              d="M144.08,30.34V5.57c0-1.33-0.71-2.57-1.87-3.23l-21.45-12.38c-1.15-0.67-2.58-0.67-3.73,0L95.59,2.34
              c-1.15,0.67-1.87,1.9-1.87,3.23v24.77c0,1.33,0.71,2.57,1.87,3.23l21.45,12.38c1.16,0.67,2.58,0.67,3.73,0l21.45-12.38
              C143.37,32.9,144.08,31.67,144.08,30.34z"
            />
            <polygon
              className="st1"
              points="122.9,11.82 133.88,12.44 125.38,19.43 128.19,30.06 118.92,24.14 109.67,30.09 112.44,19.45 
              103.92,12.49 114.9,11.84 118.88,1.58 	"
            />
          </g>
          <g>
            <path
              className="st1"
              d="M-58.08,21.55c0.29,0.41,0.64,0.78,1.04,1.07c0.41-0.3,0.76-0.66,1.04-1.07c0.24-0.33,0.43-0.69,0.56-1.07
              c0.12-0.35,0.2-0.71,0.21-1.07c0.02-0.37-0.06-0.74-0.23-1.07h-3.16c-0.17,0.33-0.25,0.7-0.23,1.07c0.02,0.37,0.09,0.73,0.21,1.07
              C-58.51,20.86-58.32,21.22-58.08,21.55z"
            />
            <path
              className="st1"
              d="M-55.99,51.85c-0.29-0.41-0.64-0.78-1.04-1.07c-0.41,0.3-0.76,0.66-1.04,1.07c-0.24,0.33-0.43,0.69-0.56,1.07
              c-0.12,0.35-0.2,0.71-0.21,1.07c-0.02,0.37,0.06,0.74,0.23,1.07h3.16c0.17-0.33,0.25-0.7,0.23-1.07c-0.02-0.37-0.09-0.73-0.21-1.07
              C-55.57,52.54-55.75,52.18-55.99,51.85z"
            />
            <path
              className="st1"
              d="M-72.19,35.66c-0.33-0.24-0.69-0.43-1.07-0.56c-0.35-0.12-0.71-0.2-1.07-0.21c-0.37-0.02-0.74,0.06-1.07,0.23
              v3.16c0.33,0.17,0.7,0.25,1.07,0.23c0.37-0.02,0.73-0.09,1.07-0.21c0.38-0.13,0.75-0.32,1.07-0.56c0.41-0.29,0.78-0.64,1.07-1.04
              C-71.42,36.29-71.78,35.94-72.19,35.66z"
            />
            <path
              className="st2"
              d="M-44.68,29.55l-11.2,5.29c-1.03-0.64-2.38-0.33-3.02,0.7c-0.64,1.03-0.33,2.38,0.7,3.02
              c1.03,0.64,2.38,0.33,3.02-0.7c0.2-0.32,0.31-0.68,0.33-1.06l10.73-6.2c0.28-0.16,0.38-0.53,0.22-0.81
              C-44.07,29.52-44.4,29.42-44.68,29.55L-44.68,29.55z"
            />
            <path
              className="st3"
              d="M-55.48,13.02v-4.3l3.64-1.48c0.24-0.1,0.4-0.33,0.4-0.59V4.13c0-0.35-0.28-0.63-0.63-0.63c0,0,0,0,0,0H-62
              c-0.35,0-0.63,0.28-0.63,0.63v2.53c0,0.26,0.16,0.49,0.4,0.59l3.64,1.48v4.3 M-39.18,21.08l3.11-3.11l1.5,1.5
              c0.34,0.34,0.9,0.34,1.24,0l1.82-1.82c0.46-0.45,0.47-1.2,0.01-1.66c-0.06-0.06-0.13-0.12-0.21-0.17
              c-1.77-1.17-3.29-2.69-4.46-4.46c-0.35-0.55-1.07-0.71-1.62-0.37c-0.08,0.05-0.15,0.11-0.21,0.17l-1.82,1.82
              c-0.34,0.34-0.34,0.9,0,1.24l1.5,1.5l-3.11,3.11"
            />
            <g>
              <path
                className="st3"
                d="M-56.9,14.42c12.21,0,22.14,9.93,22.14,22.14S-44.7,58.69-56.9,58.69s-22.14-9.93-22.14-22.14
                  S-69.11,14.42-56.9,14.42 M-56.9,12.61c-13.22,0-23.95,10.72-23.95,23.95S-70.13,60.5-56.9,60.5s23.95-10.72,23.95-23.95
                  S-43.68,12.61-56.9,12.61L-56.9,12.61z"
              />
            </g>
          </g>
          <g>
            <polygon
              className="st1"
              points="15.4,16.39 21.75,16.74 16.84,20.78 18.46,26.93 13.1,23.51 7.75,26.95 9.35,20.8 4.42,16.77 
              10.77,16.39 13.08,10.46 	"
            />
            <polygon
              className="st1"
              points="37.31,7.42 43.66,7.78 38.75,11.82 40.37,17.97 35.01,14.54 29.66,17.99 31.26,11.83 26.34,7.81 
              32.69,7.43 34.99,1.5 	"
            />
            <polygon
              className="st1"
              points="59.23,25.35 65.58,25.71 60.66,29.75 62.28,35.9 56.92,32.47 51.58,35.92 53.18,29.76 48.25,25.74 
              54.6,25.36 56.9,19.43 	"
            />
            <rect
              x="2.13"
              y="31.61"
              className="st0"
              width="21.91"
              height="26.89"
            />
            <rect
              x="24.04"
              y="22.64"
              className="st0"
              width="21.91"
              height="35.86"
            />
            <rect
              x="45.96"
              y="40.57"
              className="st0"
              width="21.91"
              height="17.93"
            />
          </g>
        </svg>
      </div>
      <div className="ni-label">Leaderboard</div>
    </NavLink>
  );

  navLinks.push(
    <a
      href="https://shop.oneus.run"
      target="_blank"
      className="nav-item"
      key="shop"
    >
      <div className="ni-icon">
        <svg width="70" height="60" viewBox="0 0 70 60">
          <g>
              <g>
                <path
                  className="st0"
                  d="M18.82,55.66C22.35,56.6,29.21,57.3,35,57.3s12.65-0.7,16.18-1.65c-0.13-1.75-0.87-11.11-2.61-15.22
				c-2.07-4.86-2.17-9.5-0.36-15.51c1.64-5.44,0.81-8.19-0.16-11.38c-0.1-0.34-0.21-0.68-0.31-1.03c-0.93-3.2-0.52-7.47-0.39-8.56
				c-0.68-0.29-2.15-0.82-2.93-1.09c0.25,2.88,0.63,8.38-2.36,11.65c-1.61,1.76-3.98,2.65-7.06,2.65s-5.46-0.89-7.06-2.65
				c-2.99-3.27-2.61-8.77-2.36-11.65c-0.78,0.28-2.25,0.8-2.93,1.09c0.13,1.1,0.54,5.37-0.39,8.56c-0.1,0.35-0.2,0.69-0.31,1.03
				c-0.97,3.19-1.8,5.95-0.16,11.38c1.81,6.01,1.71,10.65-0.36,15.51C19.69,44.54,18.95,53.91,18.82,55.66z"
                />
              </g>
            <path
              className="st1"
              d="M37.69,22.13h-5.41l-1.85,1.65v12.44l1.85,1.64h5.41l1.87-1.64V23.79L37.69,22.13z M36.41,34.94h-2.54v-7.69
		h-0.73v-1.84l1.57-0.42h1.7V34.94z"
            />
          </g>
        </svg>
      </div>
      <div className="ni-label">Shop</div>
    </a>
  );

  return (
    <>
      <div className={headerClass}>
        <Link to={"/"} id="homelink">
          <img src="img/One-Logo.png" alt="One Logo" />
        </Link>
        {signOut}
      </div>

      <div className="main-nav">{navLinks}</div>
    </>
  );
};

export default MainNav;
