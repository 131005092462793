import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { auth } from "./firebase.js";

interface MyFormValues {
  email?: string;
  password?: string;
}

const Register = ({ history }: RouteComponentProps) => {
  return (
    <div className="log">
      <div className="nameCard card">
        <div>Welcome to the Challenge!</div>
      </div>

      <div className="link card">
        <div className="cardHead">Create Your Account</div>
        <div className="instructions">
            If you're having any issues please email <a href="mailto:techsupport@oneus.run">techsupport@oneus.run</a>.
        </div>
        <div className="form">
          <Formik
            initialValues={{ email: "", password: "", confirmPassword: "" }}
            validate={(values) => {
              const errors: MyFormValues = {};
              if (!values.email) {
                errors.email = "Email Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password || !values.confirmPassword) {
                errors.password = "Password Required";
              } else if (values.password !== values.confirmPassword) {
                errors.password = "Passwords do not match";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await auth.createUserWithEmailAndPassword(
                  values.email,
                  values.password
                );
                history.replace("/log");
              } catch (error) {
                alert(error.message);
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                  <label>Email Address</label>
                <Field type="email" name="email" placeholder="Email"/>
                <ErrorMessage name="email" component="div" className="error" />
                <label>Password</label>
                <Field type="password" name="password" placeholder="Password" />
                <label>Confirm Your Password</label>
                <Field type="password" name="confirmPassword" />
                <ErrorMessage name="password" component="div" className="error" />
                <button type="submit" disabled={isSubmitting}>
                  Register
                </button>
              </Form>
            )}
          </Formik>
          <Link to="/login" className="login">Already have an account? Go to Login.</Link>
        </div>
        
      </div>
    </div>
  );
};

export default withRouter(Register);
