import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { toASCII } from "punycode";


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
};


firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

/*
if (window.location.hostname === "localhost") {
    console.log("localhost detected!");
    db.settings({
      host: "localhost:8080",
      ssl: false
    });
}
*/
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const fb_functions = firebase.functions();


export const getProfiles = userID => {
    return db.collection("users").where("fb_uid", "==", userID).get().then(qSnap => {
        let profiles = [];
        qSnap.forEach(doc => {
            profiles.push(doc.data());
        });
        return profiles;
    })
        .catch(error => {
            console.log("error retriving profiles: " + error);
        });
}

export const getActivityLog = (docID, userID) => {
    return db.collection("users").doc(docID.toString()).collection("activities").orderBy('day', 'desc').where("fb_uid", "==", userID).get().then(qSnap => {
        let activities = [];
        qSnap.forEach(doc => {
            let data = doc.data();
            data.id = doc.id;
            activities.push(data);
        });
        return activities;
    })
        .catch(error => {
            console.log("error retriving activities: " + error);
        });
}

export const deleteActivity = (aid, uid) => {
    return db.collection("users").doc(uid.toString()).collection("activities").doc(aid).delete().then(doc => { return "deleted" });
}


export const getIndiLeaderboard = (eventID, timing) => {
    return db.collection("events").doc(eventID.toString()).collection("leaderboards").doc(timing).get().then(snapshot => {
        let entries = [];
        if (snapshot.exists)
            entries = snapshot.data().leaderboard.map((e) => {
                let temp = e.split("|");
                return {
                    n: temp[0] + " " + temp[1],
                    fn: temp[0],
                    ln: temp[1],
                    mi: parseFloat(temp[2]),
                    km: parseFloat(temp[3]),
                    prD: parseFloat(temp[4]),
                    prDP: temp[5],
                    prP: parseFloat(temp[6]),
                    prPP: temp[7],
                    g: temp[8],
                    ag: temp[9],
                    c: temp[10],
                    st: temp[11]
                };

            });

        entries.sort((a, b) => {
            return b.mi - a.mi;
        });


        return entries;
    })
        .catch(error => {
            console.log("error retriving activities: " + error);
        });
}

export const getTeamLeaderboard = (eventID, timing) => {
    return db.collection("teams").where("event_id", "==", eventID).get().then(snapshot => {
        let entries = [];
        if (!snapshot.empty)
            entries = snapshot.docs.reduce((result, e) => {
                let temp = e.data();
                if (temp.total > 0) {
                    let percent = temp.totalPercent > 1 ? 100 : (temp.totalPercent*100).toFixed(0);
                    let count = temp.members.length;
                    result.push({
                        n: temp.name,
                        fn: "",
                        ln: temp.name,
                        mi: temp.total.toFixed(1),
                        prc: percent,
                        count: count,
                        avg: (temp.total/count).toFixed(1)
                    });
                }
                return result;
            }, []);

        entries.sort((a, b) => {
            return b.mi - a.mi;
        });


        return entries;
    })
        .catch(error => {
            console.log("error retriving activities: " + error);
        });
}
