import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import MainNav from "./MainNav";
import Register from "./Register";
import Login from "./Login";
import Password from "./Password";
import Leaderboard from "./Leaderboard";
import Home from "./Home";
import Log from "./Log";
import Achievements from "./Achievements";
import { AuthProvider } from "./Auth";

import PrivateRoute from "./PrivateRoute";

const App = () => {
 
  

  return (
    <AuthProvider>
      <div className="container">
        <Router>
          <MainNav />
          <Route exact path="/" component={Home} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/password" component={Password} />
          <Route exact path="/leaderboard" component={Leaderboard} />
          <PrivateRoute exact path="/log" component={Log} />
          <PrivateRoute exact path="/achievements" component={Achievements} />
        </Router>

        <div className="footer">
          <div>
            &copy; OneUS.run by Karmarush LLC and PrimeTime Event &amp; Race Mgmt, LLC | <a style={{paddingLeft:0,fontWeight:'bold'}} href="mailto:techsupport@oneus.run">Need Help?</a> | <img src="img/with_strava.png" style={{height:'30px', verticalAlign: 'text-bottom'}} alt="Compatible with Strava"/>
          </div>
          
        </div>
      </div>
    </AuthProvider>
  );
};

export default App;
