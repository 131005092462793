import { AuthContext } from "./Auth.jsx";
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  getProfiles,
  firestore,
} from "./firebase.js";

const Achievements = () => {

  const { currentUser } = useContext(AuthContext);
  const [error, setError] = useState();
  const [profiles, setProfiles] = useState([]);
  const [stravaInfo, setStravaInfo] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState();

  useEffect(() => {
    console.log("get profiles:" + currentUser.uid);
    firestore
      .collection("users")
      .where("fb_uid", "==", currentUser.uid)
      .onSnapshot((qSnap) => {
        let profileList = [];
        console.log("size:" + qSnap.size);
        if (qSnap.size > 0) {
          qSnap.forEach((doc) => {
            let data = doc.data();
            profileList.push(data);
            if (
              selectedProfile === undefined ||
              selectedProfile.user_id === data.user_id
            ) {
              console.log("set profiles");
              setError(null);
              setSelectedProfile(data);
            }
          });
          setProfiles(profileList);
        } else {
          //go to log page
        }
      });
  }, [currentUser.uid]);

  let badges = [];
  if (profiles.length > 0) {
    let distanceBadges = [];
    let dayStreakBadges = [];
    let weekStreakBadges = [];

    let distanceZeroCount=0;
    let unlockMore = true;

    if (selectedProfile.distanceMilestones) {
      Object.keys(selectedProfile.distanceMilestones).forEach(function (streak,i) {
        let common = selectedProfile.distanceMilestones[streak].common;
        let distance = common.replace("m", " miles").replace("k", " kilometers");
        if (selectedProfile.distanceMilestones[streak].count > 0) {
          let day =getUTCDate(selectedProfile.distanceMilestones[streak].last);
          distanceBadges.push(<div className="badge active" key={"distance" + common}><img src={"/img/badges/distance-" + common + ".png"} /><b>{distance}</b>{day}</div>);
          if(i+1 == selectedProfile.distanceMilestones.length) unlockMore=false;
        } else if (distanceZeroCount<4) {
          distanceZeroCount++;
          distanceBadges.push(<div className="badge inactive" key={"distance" + common}><img src={"/img/badges/distance-" + common + ".png"} /><b>{distance}</b></div>);
          if(i+1 == selectedProfile.distanceMilestones.length) unlockMore=false;
        } else if (unlockMore) {
          distanceBadges.push(<div className="badge unlock" key={"distance-unlock"}><img src={"/img/badges/distance-unlock.png"} /><b>Earn to Unlock</b></div>);
          unlockMore = false;
        }
      });
    }

    if (selectedProfile.dayStreaks) {
      Object.keys(selectedProfile.dayStreaks).forEach(function (streak) {
        if (selectedProfile.dayStreaks[streak].count > 0) {
          let times = selectedProfile.dayStreaks[streak].count === 1 ? "time" : "times";
          dayStreakBadges.push(<div className="badge active" key={"day" + streak}><img src={"/img/badges/day-" + streak + ".png"} /><b>{streak}-Day Streak</b>{selectedProfile.dayStreaks[streak].count} {times}</div>);
        } else {
          dayStreakBadges.push(<div className="badge inactive" key={"day" + streak}><img src={"/img/badges/day-" + streak + ".png"} /><b>{streak}-Day Streak</b></div>);
        }
      });
    }

    if (selectedProfile.weekStreaks) {
      Object.keys(selectedProfile.weekStreaks).forEach(function (streak) {
        if (selectedProfile.weekStreaks[streak].count > 0) {
          let times = selectedProfile.weekStreaks[streak].count === 1 ? "time" : "times";
          weekStreakBadges.push(<div className="badge active" key={"week" + streak}><img src={"/img/badges/week-" + streak + ".png"} /><b>{streak}-Week Streak</b>{selectedProfile.weekStreaks[streak].count} {times}</div>);
        } else {
          weekStreakBadges.push(<div className="badge inactive" key={"week" + streak}><img src={"/img/badges/week-" + streak + ".png"} /><b>{streak}-Week Streak</b></div>);
        }
      });
    }
  

  badges.push(<div className="grouping" key="distance"><div className="groupHead">Distance Milestones</div><div className="badges">{distanceBadges}</div></div>);
  badges.push(<div className="grouping" key="daily"><div className="groupHead">Daily Streaks</div><div className="badges">{dayStreakBadges}</div></div>);
  badges.push(<div className="grouping" key="weekly"><div className="groupHead">Weekly Streaks</div><div className="badges">{weekStreakBadges}</div></div>);

}

return (
  <>
    <div className="achievements">

      {badges}

    </div>
  </>
);
};

function getUTCDate(tsDate) {
  let temp = new Date(tsDate * 1000).toISOString().split("T")[0].split("-");
  return temp[1] + "/" + temp[2] + "/" + temp[0];
}

export default Achievements;
