import React, { useEffect, useState } from "react";
import {auth, firestore} from "./firebase.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [css, setCSS] = useState("main.css?cb=3.6");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);

      if(user!==null) {
      firestore
      .collection("users")
      .where("fb_uid", "==", user.uid)
      .get().then((snapshot) => {
          console.log(user.uid);
          console.log(snapshot.size)
          if(snapshot.size>0) {
            document.getElementById("stylesheet").setAttribute("href","/css/"+snapshot.docs[0].data().css+"?cb=3.6")
            setCSS(snapshot.docs[0].data().css);
          }
      });
    } else {
        document.getElementById("stylesheet").setAttribute("href","/css/main.css?cb=3.6")
    }

    });
  }, []);

  if(pending){
    return <div className="loading">
    <div className="spinner">
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </div>
    Loading Data
  </div>
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};