import React, { useEffect, useState, useContext, useRef } from "react";
import {
  fb_functions,
  getProfiles,
  getActivityLog,
  deleteActivity,
  firestore,
} from "./firebase.js";
import { AuthContext } from "./Auth.jsx";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { RouteComponentProps } from "react-router-dom";
//import { start } from "repl";

const Log = ({ history }) => {
  const { currentUser } = useContext(AuthContext);
  const [error, setError] = useState();
  const [profiles, setProfiles] = useState([]);
  const [needLink, setNeedLink] = useState(false);
  const [stravaInfo, setStravaInfo] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState();
  const [spActivities, setspActivities] = useState();
  const [teams, setTeams] = useState();
  const [openAdd, setOpenAdd] = useState(false);
  const [updatingLog, setUpdatingLog] = useState(false);
  const [updatingProfile, setUpdatingProfile] = useState(true);
  const [deleteID, setDeleteID] = useState(0);

  const [addIsSubmiting, setAddIsSubmiting] = useState(false);

  const [aaAct, setAAAct] = useState("run");
  const [aaMood, setAAMood] = useState();

  const aaDate = useRef();
  const aaDistance = useRef();
  const aaUnit = useRef();
  const aaHH = useRef();
  const aaMM = useRef();
  const aaSS = useRef();

  const handleAddClick = () => setOpenAdd(true);
  const handleCancelClick = () => setOpenAdd(false);

  const handleStravaInfo = () => setStravaInfo(!stravaInfo);

  const handleAAActClick = (e) => setAAAct(e.target.value);
  const handleAAMoodClick = (e) => setAAMood(e.target.value);


  const handleStravaConnect = () => {
    window.location.href="https://www.strava.com/oauth/authorize?client_id=47462&response_type=code&redirect_uri=https://oneus.run/stravaconnect&approval_prompt=force&scope=read,activity:read_all";
  };

  const handleShowDelete = (e) => {
    if (deleteID == e) setDeleteID(0);
    else setDeleteID(e);
  };

  const handleDelete = async (aid, uid) => {
    setUpdatingLog(true);
    setUpdatingProfile(true);
    await deleteActivity(aid, uid);
    getActivityLog(selectedProfile.user_id, currentUser.uid).then((aDocs) => {
      setspActivities(aDocs);
      setUpdatingLog(false);
    });
  };

  const joinChallenege = async (challengeName, uid) => {
    setJoinChallenege(challengeName, uid);
  };

  const handleAddActivityClick = async () => {
    console.log(selectedProfile.fb_uid);
    setAddIsSubmiting(true);

    if (
      aaDistance.current.value === "" ||
      aaHH.current.value === "" ||
      aaMM.current.value === "" ||
      aaSS.current.value === ""
    ) {
      setAddIsSubmiting(false);
      return;
    }

    setOpenAdd(false);
    setUpdatingLog(true);
    setUpdatingProfile(true);

    await addActivity(
      { user_id: selectedProfile.user_id },
      aaDate.current.value,
      Number.parseFloat(aaDistance.current.value),
      aaUnit.current.value,
      parseInt(aaHH.current.value),
      parseInt(aaMM.current.value),
      parseInt(aaSS.current.value),
      aaAct,
      aaMood
    );

    setAddIsSubmiting(false);
    setAAAct("run");
    setAAMood("");
    aaHH.current.value = "00";
    aaMM.current.value = "00";
    aaSS.current.value = "00";
    aaDistance.current.value = "";
    let date = new Date();
    aaDate.current.value =
      date.getFullYear().toString() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, 0) +
      "-" +
      date.getDate().toString().padStart(2, 0);

    getActivityLog(selectedProfile.user_id, currentUser.uid).then((aDocs) => {
      setspActivities(aDocs);
      setUpdatingLog(false);
    });

    //history.replace("/login");
  };

  let addClass = openAdd ? "open " : "";
  useEffect(() => {
    console.log("get profiles:" + currentUser.uid);
    firestore
      .collection("users")
      .where("fb_uid", "==", currentUser.uid)
      .onSnapshot((qSnap) => {
        let profileList = [];
        console.log("size:" + qSnap.size);
        if (qSnap.size > 0) {
          qSnap.forEach((doc) => {
            let data = doc.data();
            profileList.push(data);
            if (
              selectedProfile === undefined ||
              selectedProfile.user_id === data.user_id
            ) {
              console.log("set profiles");
              setError(null);
              setSelectedProfile(data);
              document
                .getElementById("stylesheet")
                .setAttribute("href", "/css/" + data.css + "?cb=3.6");
              setNeedLink(false);
            }
            setUpdatingProfile(false);
          });
          setProfiles(profileList);
        } else {
          setNeedLink(true);
        }
      });
  }, [currentUser.uid]);

  useEffect(() => {
    console.log("get activities");
    console.log("SP:" + selectedProfile);
    if (selectedProfile) {
      console.log("getting activities");
      getActivityLog(selectedProfile.user_id, currentUser.uid).then((aDocs) => {
        setspActivities(aDocs);
      });
      getTeams(selectedProfile.user_id);
    }
  }, [selectedProfile]);

  if (needLink) {
    return (
      <div className="log">
        <div className="nameCard card">
          <div>Let's Get Started!</div>
        </div>

        <div className="link card">
          <div className="cardHead">Link Your Registration</div>
          <div className="instructions">
            To log your activity, you have to link your registration information
            to your account. Please use the last name you used when registering
            along with the registration ID you received when you joined.{" "}
            <b>
              Your registration ID can be found in your leaderboard email and on
              the PDF that was attached to your registration confirmation email.
            </b>{" "}
            If you're having any issues please email
            <a href="mailto:techsupport@oneus.run">techsupport@oneus.run</a>.
          </div>
          <div className="form">
            <Formik
              initialValues={{ last_name: "", regID: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.last_name) {
                  errors.last_name = "Last Name Required";
                }

                if (!values.regID) {
                  errors.regID = "Registration ID Required";
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, setStatus }) => {
                console.log("call");
                return linkRegistrations(values.last_name, values.regID)
                  .then((link) => {
                    console.log("return");
                    if (link.success) {
                      setSubmitting(false);
                      history.replace("/login");
                    } else {
                      alert(link.error);
                      setSubmitting(false);
                    }
                  })
                  .catch((error) => {
                    alert(error.message);
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <label>Last Name</label>
                  <Field type="text" name="last_name" placeholder="Last Name" />
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="error"
                  />
                  <label>Registration ID</label>
                  <Field
                    type="number"
                    name="regID"
                    placeholder="Registration ID"
                  />
                  <ErrorMessage
                    name="regID"
                    component="div"
                    className="error"
                  />
                  <button type="submit" disabled={isSubmitting}>
                    Link Registration
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  } else if (profiles.length > 0) {
    /* SUMMARY */
    let activityList = ["run", "indoor", "walk", "race", "trail", "wc"];
    let activties = [];
    let totalCount = 0;
    let totalMiles = 0;
    let totalKM = 0;
    if (selectedProfile.totals && selectedProfile.totals.overall) {
      totalCount = selectedProfile.totals.overall.count;
      totalMiles = selectedProfile.totals.overall.miles.toFixed(2);
      totalKM = selectedProfile.totals.overall.km.toFixed(2);
    }

    let activityTotals = selectedProfile.totals;
    activties = activityList.map((a) => {
      if (selectedProfile.totals && selectedProfile.totals[a]) {
        let at = selectedProfile.totals[a].time
          ? selectedProfile.totals[a].time
          : null;
        return (
          <div className="activity" key={a}>
            <div className="icon">
              <img src={`img/activity-${a}.svg`} alt={a} />
            </div>
            <div className="type">
              {a} ({selectedProfile.totals[a].count})
            </div>
            <div className="detail">
              <div className="distance">
                {selectedProfile.totals[a].miles.toFixed(2)} miles
              </div>
              <div className="time"></div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="activity zero" key={a}>
            <div className="icon">
              <img src={`img/activity-${a}.svg`} alt={a} />
            </div>
            <div className="type">{a} (0)</div>
          </div>
        );
      }
    });

    let tcText;
    let tdText = `${totalMiles} miles (${totalKM} km)`;
    if (updatingProfile) {
      tcText = "Updating Activities";
    } else if (totalCount === 0) {
      tcText = "No Acitvities Logged";
    } else if (totalCount === 1) {
      tcText = "1 Activity Logged";
    } else {
      tcText = `${totalCount} Activities Logged`;
    }

    //
    /* Blackhawks 7 day */
    let bh7day = null;
    if (selectedProfile.css === "OneIL.css") {
      let today = new Date();
      let startDate = new Date("2020-07-13T00:00:00.00-05:00");
      today.setHours(0, 0, 0, 0);

      if (selectedProfile.bh7d) {
        let bh7dStats = []
        let bh7dStatus = "Your Progress";
        if (selectedProfile.bh7dStats) {
          if(selectedProfile.bh7dStats.achieved) bh7dStatus="You Earned the Blackhawks 7 Day Challenge Badge!"
          let keys = Object.keys(selectedProfile.bh7dStats);
          if(keys.includes("1594598400")){bh7dStats.push(<div key="m"><i className="material-icons-outlined achieved">check_circle</i>Mon</div>) } else {bh7dStats.push(<div><i className="material-icons-outlined dim">radio_button_unchecked</i>Mon</div>)}
          if(keys.includes("1594684800")){bh7dStats.push(<div key="t"><i className="material-icons-outlined achieved">check_circle</i>Tue</div>) } else {bh7dStats.push(<div><i className="material-icons-outlined dim">radio_button_unchecked</i>Tue</div>)}
          if(keys.includes("1594771200")){bh7dStats.push(<div key="w"><i className="material-icons-outlined achieved">check_circle</i>Wed</div>) } else {bh7dStats.push(<div><i className="material-icons-outlined dim">radio_button_unchecked</i>Wed</div>)}
          if(keys.includes("1594857600")){bh7dStats.push(<div key="r"><i className="material-icons-outlined achieved">check_circle</i>Thu</div>) } else {bh7dStats.push(<div><i className="material-icons-outlined dim">radio_button_unchecked</i>Thu</div>)}
          if(keys.includes("1594944000")) {bh7dStats.push(<div key="f"><i className="material-icons-outlined achieved">check_circle</i>Fri</div>) } else {bh7dStats.push(<div><i className="material-icons-outlined dim">radio_button_unchecked</i>Fri</div>)}
          if(keys.includes("1595030400")){bh7dStats.push(<div key="s"><i className="material-icons-outlined achieved">check_circle</i>Sat</div>) } else {bh7dStats.push(<div><i className="material-icons-outlined dim">radio_button_unchecked</i>Sat</div>)}
          if(keys.includes("1595116800")) {bh7dStats.push(<div key="su"><i className="material-icons-outlined achieved">check_circle</i>Sun</div>) } else {bh7dStats.push(<div><i className="material-icons-outlined dim">radio_button_unchecked</i>Sun</div>)}
        } else {
          bh7dStats.push(<div key="m"><i className="material-icons-outlined dim">radio_button_unchecked</i>Mon</div>);
          bh7dStats.push(<div key="t"><i className="material-icons-outlined dim">radio_button_unchecked</i>Tue</div>);
          bh7dStats.push(<div key="w"><i className="material-icons-outlined dim">radio_button_unchecked</i>Wed</div>);
          bh7dStats.push(<div key="r"><i className="material-icons-outlined dim">radio_button_unchecked</i>Thu</div>);
          bh7dStats.push(<div key="f"><i className="material-icons-outlined dim">radio_button_unchecked</i>Fri</div>);
          bh7dStats.push(<div key="s"><i className="material-icons-outlined dim">radio_button_unchecked</i>Sat</div>);
          bh7dStats.push(<div key="su"><i className="material-icons-outlined dim">radio_button_unchecked</i>Sun</div>);
        }
        bh7day = (
          <div className="bh7d card">
            <div className="cardHead">Blackhawks 7 Day Challenge</div>

            <div className="challengeContent">
              <div>
                <img src="img/challenges/Blackhawks-7Day.png"></img>
              </div>
              <div>
                <div className="challengeHead">
                  {bh7dStatus}
                </div>
                <div className="streakCheck">
                  {bh7dStats}
                </div>
                <div><small>
                  The first 5 participants to log a run every day for a week
                  starting <b>Monday July 13th</b>, take a picture and tag
                  #RunAsOneIllinois and #Blackhawks on social will win a puck
                  signed by Alex DeBrincat.<br/>Everyone who completes the challenge with a 7 day activity streak will earn the Blackhawks 7 Day Challenge virtual badge.</small>
                </div>
              </div>
            </div>
          </div>
        );
      } else if (today <= startDate) {
        bh7day = (
          <div className="bh7d card">
            <div className="cardHead">Blackhawks 7 Day Challenge</div>
            <div className="challengeContent">
              <div>
                <img src="img/challenges/Blackhawks-7Day.png"></img>
              </div>
              <div>
                <div className="challengeHead">Join the Challenge!</div>
                <div>
                  The first 5 participants to log a run every day for a week
                  starting <b>Monday July 13th</b>, take a picture and tag
                  #RunAsOneIllinois and #Blackhawks on social will win a puck
                  signed by Alex DeBrincat.<br/><br/>Everyone who completes the challenge with a 7 day activity streak will earn the Blackhawks 7 Day Challenge virtual badge.
                </div>
                <div>
                  <button
                    className="btnJoin"
                    onClick={() =>
                      joinChallenege("bh7d", selectedProfile.user_id)
                    }
                  >
                    COUNT ME IN!
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    /* PRS */
    let pr = [];
    if (selectedProfile.records) {
      pr = Object.keys(selectedProfile.records).map((a) => {
        return (
          <div className="best" key={a}>
            <div className="icon">
              <img src={`img/pr-${a}.svg`} alt={`${a} PR`} />
            </div>
            <div className="label">{selectedProfile.records[a].desc}</div>
            <div className="mark">{selectedProfile.records[a].mark}</div>
            <div className="date">
              {getUTCDate(selectedProfile.records[a].date)}
            </div>
          </div>
        );
      });
    } else {
      pr.push(
        <div className="noresults" key="noresult">
          No Bests - Log an Activity to Start{" "}
        </div>
      );
    }

    /* LOG */
    let aLog = [];
    let logInstructions = "";
    if (spActivities && spActivities.length > 0) {
      logInstructions =
        "Click on a row to bring up the delete button. You can delete and then re-add an entry.";
      aLog = spActivities.map((a, i) => {
        let t = null;
        let r = null;
        if (a.time) {
          t = (
            <>
              Time: {getCleanTime(a.time)}
              <br />
              Pace: {getCleanTime(a.pace)}/mi
              <br />
            </>
          );
        }
        if (a.rating) {
          r = <img src={`img/mood-${a.rating}.svg`} alt={a.rating} />;
        }

        let deleteIcon = null;

        if (deleteID === a.id) {
          deleteIcon = (
            <div
              className="deleteIcon"
              onClick={() => handleDelete(a.id, a.user_id)}
            >
              <img src="img/delete.png" />
            </div>
          );
        }
        let stravaLogIcon = a.strava_id ? <img src="img/strava_symbol_orange.png" className="stravaLogIcon" />  : null;
        return (
          <div className="item" onClick={() => handleShowDelete(a.id)} key={i}>
            <img src={`img/activity-${a.activity}.svg`} alt={a.activity} />
            <div className="info">
              <div className="distance">
                <div className="miles">{a.miles.toFixed(2)} mi</div>
                {a.km.toFixed(2)} km
                <div className="date">{getUTCDate(a.day)}</div>
              </div>
              <div className="time">
                
                <div>
                  {t}
                  {r}
                </div>
                {stravaLogIcon}
                {deleteIcon}
              </div>
            </div>
          </div>
        );
      });
    } else {
      aLog.push(
        <div className="noresults" key="noresult">
          No Activities - Log an Activity to Start{" "}
        </div>
      );
    }

    let tCards = [];
    if (teams && teams.length > 0) {
      tCards = teams.map((t, i) => {
        let members = Object.values(t.member_details);
        //console.log(members);
        members.sort((a, b) => {
          return b.miles - a.miles;
        });

        let tLog = members.map((m) => {
          return (
            <div className="member" key={m.user_id}>
              <div className="name">{m.name}</div>
              <div className="miles">{m.miles.toFixed(2)}</div>
            </div>
          );
        });

        let total = t.total ? t.total.toFixed(2) : 0;
        let totalPercent = t.totalPercent ? t.totalPercent * 100 : 0;
        let totalPercentString = totalPercent.toFixed(1) + "%";
        let progressPercent = totalPercent > 100 ? "100" : totalPercentString;
        let teamGoalText =
          t.event_id == 407133
            ? "Run Around the Region"
            : "Run Around the State";

        if (t.members.length > 0) {
          return (
            <div className={"teams card"} key={t.team_id}>
              <div className="cardHead">{t.name}</div>
              <div className="totals">
                <small>{teamGoalText}</small>
                <br />
                {total} of {t.team_goal_mark} miles
                <div className="progressbar">
                  <div className="progress" style={{ width: progressPercent }}>
                    {totalPercentString}
                  </div>
                </div>
              </div>

              <div className="log-list">
                <div className="member head">
                  <div className="name">Teammate</div>
                  <div className="miles">Miles</div>
                </div>
                {tLog}
              </div>
            </div>
          );
        } else {
          return null;
        }
      });
    }

    let updatingClass = "";
    let prHead = "Personal Records";
    if (updatingProfile) {
      updatingClass = " updating";
      tcText = "Updating Activities";
      prHead = "Updating Records";
    }

    let alHead = updatingLog ? "Updating Activity Log" : "Activity Log";
    let alClass = updatingLog ? " updating" : "";

    let strava =null;
    let stravaInfoDiv = null;
    if(selectedProfile.strava) {

      if(stravaInfo) {
        stravaInfoDiv = <div className="stravaInfoText">We'll automatically import all Run (run, race, treadmill), Walk, Hike &amp; Wheelchair activites. Activites typically sync within 15 minutes of adding it to Strava. Your miles will update automatically. <a target="_blank" href="https://www.strava.com/dashboard">View on Strava</a> </div>;
      }

      strava = <div className="strava"><div className="connected stravaStatus">CONNECTED TO STRAVA</div><div className="stravaInfo"><i className="material-icons-outlined" onClick={handleStravaInfo}>
      help_outline
    </i></div>{stravaInfoDiv}</div>;
    } else {
      

      if(stravaInfo) {
        stravaInfoDiv = <div className="stravaInfoText">Connect to your Strava account and we'll automatically import all Run (run, race, treadmill), Walk, Hike &amp; Wheelchair activites. The initial sync will get all activities after the date of your last currently logged entry and will begin showing within a few minutes of syncing. After that, activites typically sync within 15 minutes of adding it to Strava. Your miles will update automatically. </div>;
      }

      strava = <div className="strava"><div className="stravaStatus connect"  onClick={handleStravaConnect}><img src="img/btn_strava_connect.png" style={{height:'48px'}} alt="Connect to Strava" /></div><div className="stravaInfo" onClick={handleStravaInfo}><i className="material-icons-outlined">
      help_outline
      </i></div>{stravaInfoDiv}</div>;
/*
      if(!(selectedProfile.css==="OneIL.css" || selectedProfile.css==="OneWI.css" || selectedProfile.css==="OneIA.css")) {
        strava=null;
      }
 */
    }

    return (
      <div className="log">
        <div className="nameCard card">
          <div>
            {selectedProfile.first_name} {selectedProfile.last_name}
          </div>
        </div>
        
        <div className={"summary card" + updatingClass}>
          <div className="cardHead">{tcText}</div>
          {strava}
          <div className="totals">
            {totalMiles} miles ({totalKM} km)
          </div>
          <div className="activitySummary">{activties}</div>
          
          <div
            className={addClass + "add"}
            id="addActivityBtn"
            onClick={handleAddClick}
          >
            Log A New Activity
          </div>
          <div className={addClass + "addActivity"} id="addActivity">
            <div className="instructions">
              Date, distance and activity are required. Time is optional and is
              used to track your pace and also break ties on the leaderboard.
              Your rating (how did you feel during/after) is also optional.
            </div>

            <div className="form">
              <div>
                <label>Date*</label>
                <input
                  type="date"
                  ref={aaDate}
                  defaultValue={new Date().toISOString().substr(0, 10)}
                />
              </div>
              <div>
                <label>Distance*</label>
                <input
                  ref={aaDistance}
                  id="distance"
                  type="number"
                  placeholder="distance"
                  step="0.1"
                  min="0"
                />
                <select ref={aaUnit}>
                  <option value="miles">miles</option>
                  <option value="km">km</option>
                </select>
              </div>
              <div>
                <label>Finish Time</label>
                <div className="timeWrapper">
                  <div className="timefield">
                    <div className="timeLabel">Hours</div>
                    <input
                      ref={aaHH}
                      id="time_hh"
                      type="number"
                      min="0"
                      max="24"
                      step="1"
                      placeholder="HH"
                      className="timeInput"
                      defaultValue="00"
                    />
                  </div>
                  <div className="timeColon">:</div>
                  <div className="timefield">
                    <div className="timeLabel">Minutes</div>
                    <input
                      ref={aaMM}
                      id="time_mm"
                      type="number"
                      min="0"
                      max="59"
                      step="1"
                      placeholder="MM"
                      className="timeInput"
                      defaultValue="00"
                    />
                  </div>
                  <div className="timeColon">:</div>
                  <div className="timefield">
                    <div className="timeLabel">Seconds</div>
                    <input
                      ref={aaSS}
                      id="time_ss"
                      type="number"
                      min="0"
                      max="59"
                      step="1"
                      placeholder="SS"
                      className="timeInput"
                      defaultValue="00"
                    />
                  </div>
                </div>
              </div>
              <div>
                <label>Activity*</label>
                <div className="activities">
                  <label className="activity">
                    <input
                      type="radio"
                      name="activity"
                      value="run"
                      onChange={handleAAActClick}
                      checked={aaAct === "run"}
                    />
                    <img src="img/activity-run.svg" alt="run" />
                    <div className="type">Run</div>
                  </label>
                  <label className="activity">
                    <input
                      type="radio"
                      name="activity"
                      value="indoor"
                      onChange={handleAAActClick}
                      checked={aaAct === "indoor"}
                    />
                    <img src="img/activity-indoor.svg" alt="indoor" />
                    <div className="type">Indoor</div>
                  </label>

                  <label className="activity">
                    <input
                      type="radio"
                      name="activity"
                      value="walk"
                      onChange={handleAAActClick}
                      checked={aaAct === "walk"}
                    />
                    <img src="img/activity-walk.svg" alt="walk" />
                    <div className="type">Walk</div>
                  </label>

                  <label className="activity">
                    <input
                      type="radio"
                      name="activity"
                      value="race"
                      onChange={handleAAActClick}
                      checked={aaAct === "race"}
                    />
                    <img src="img/activity-race.svg" alt="race" />
                    <div className="type">Race</div>
                  </label>

                  <label className="activity">
                    <input
                      type="radio"
                      name="activity"
                      value="trail"
                      onChange={handleAAActClick}
                      checked={aaAct === "trail"}
                    />
                    <img src="img/activity-trail.svg" alt="trail" />
                    <div className="type">Trail</div>
                  </label>

                  <label className="activity">
                    <input
                      type="radio"
                      name="activity"
                      value="wc"
                      onChange={handleAAActClick}
                      checked={aaAct === "wc"}
                    />
                    <img src="img/activity-wc.svg" alt="wc" />
                    <div className="type">Wheelchair</div>
                  </label>
                </div>
              </div>
              <div>
                <label>Rating</label>
                <div className="moods">
                  <label className="mood">
                    <input
                      type="radio"
                      name="mood"
                      value="great"
                      onChange={handleAAMoodClick}
                      checked={aaMood === "great"}
                    />
                    <img src="img/mood-great.svg" alt="great" />
                  </label>
                  <label className="mood">
                    <input
                      type="radio"
                      name="mood"
                      value="good"
                      onChange={handleAAMoodClick}
                      checked={aaMood === "good"}
                    />
                    <img src="img/mood-good.svg" alt="good" />
                  </label>
                  <label className="mood">
                    <input
                      type="radio"
                      name="mood"
                      value="ok"
                      onChange={handleAAMoodClick}
                      checked={aaMood === "ok"}
                    />
                    <img src="img/mood-ok.svg" alt="ok" />
                  </label>
                  <label className="mood">
                    <input
                      type="radio"
                      name="mood"
                      value="bad"
                      onChange={handleAAMoodClick}
                      checked={aaMood === "bad"}
                    />
                    <img src="img/mood-bad.svg" alt="bad" />
                  </label>
                </div>
              </div>
              <button
                className="save"
                onClick={handleAddActivityClick}
                disabled={addIsSubmiting}
              >
                Save to Activity Log
              </button>
              <button className="cancel" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
        </div>

        <div className={"personalBests card" + updatingClass}>
          <div className="cardHead">{prHead}</div>
          <div className="bests">{pr}</div>
        </div>
        {bh7day}
        {tCards}

        <div className={"activityLog card" + alClass}>
          <div className="cardHead">{alHead}</div>
          <div className="instructions">{logInstructions}</div>
          <div className="log-list">{aLog}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="loading">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
        Loading Data
      </div>
    );
  }

  function getUTCDate(tsDate) {
    let temp = new Date(tsDate * 1000).toISOString().split("T")[0].split("-");
    return temp[1] + "/" + temp[2] + "/" + temp[0];
  }
  function getCleanTime(rawmin) {
    let timestamp = Math.round(rawmin * 60);
    let hours = Math.floor(timestamp / 60 / 60);
    let minutes = Math.floor(timestamp / 60) - hours * 60;
    let seconds = Math.ceil(timestamp % 60);

    if (hours > 0) {
      return (
        hours.toString() +
        ":" +
        minutes.toString().padStart(2, "0") +
        ":" +
        seconds.toString().padStart(2, "0")
      );
    } else {
      return minutes.toString() + ":" + seconds.toString().padStart(2, "0");
    }
  }

  function getTeams(uid) {
    firestore
      .collection("teams")
      .where("members", "array-contains", uid)
      .onSnapshot((qSnap) => {
        let teams = [];
        qSnap.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          teams.push(data);
        });
        setTeams(teams);
      });
  }

  function linkRegistrations(last_name, regID) {
    if (window.location.hostname === "localhost") {
      fb_functions.useFunctionsEmulator("http://localhost:5001");
    }
    var linkRegistration = fb_functions.httpsCallable("linkRegistration");

    console.log("call function");
    return linkRegistration({
      last_name: last_name,
      regID: parseInt(regID),
    })
      .then(function (result) {
        console.log("result:" + result.data);
        return result.data;
      })
      .catch(() => {
        return { error: "Unknown error, please try again shortly." };
      });
  }

  function setJoinChallenege(challengeName, uid) {
    const userRef = firestore.collection("users").doc(uid.toString());

    const res = userRef.set(
      {
        [challengeName]: true,
      },
      { merge: true }
    );
  }

  async function addActivity(
    profile,
    day,
    distance,
    unit,
    hh,
    mm,
    ss,
    activity,
    rating
  ) {
    if (window.location.hostname === "localhost") {
      fb_functions.useFunctionsEmulator("http://localhost:5001");
    }

    var addActivityLog = fb_functions.httpsCallable("addActivityLog");

    return addActivityLog({
      profileid: profile.user_id,
      day: day,
      distance: distance,
      unit: unit,
      hh: hh,
      mm: mm,
      ss: ss,
      activity: activity,
      rating: rating,
    }).then(function (result) {
      return result.data;
    });
  }
};

export default Log;
