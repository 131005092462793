import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "./Auth.jsx";
import { getIndiLeaderboard, getTeamLeaderboard, firestore } from "./firebase.js";
import { List, WindowScroller, AutoSizer } from "react-virtualized";
import "react-virtualized/styles.css";
import { isError } from "util";

const Leaderboard = () => {
  const { currentUser } = useContext(AuthContext);
  const [entries, setEntries] = useState();
  const [filtered, setFiltered] = useState([]);
  const [cards, setCards] = useState([]);
  const [eventID, setEventID] = useState();
  const [lBType, setLBType] = useState("i");
  const [showMyLB, setShowMyLB] = useState(null);
  const [timing, setTiming] = useState("overall");
  const [group, setGroup] = useState("");
  const [gender, setGender] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    
    async function getUser() {
        if (currentUser === null) {
            setEventID(402986);
        } else {
        
            let user = await firestore
            .collection("users")
            .where("fb_uid", "==", currentUser.uid).get();
            user.docs[0].ref
            .collection("events").get().then(snapshot=>{
                if(!snapshot.empty) {
                    let data = snapshot.docs[0].data();
                    setShowMyLB(true);
                    setEventID(data.event_id);
                }
            });
        }
    }
    
    getUser();
  }, []);
  useEffect(() => {
    if (eventID && lBType==="i") {
      getIndiLeaderboard(eventID, timing)
        .then((data) => {
          if (data === undefined) data = null;
          setEntries(data);
        })
        .catch(() => console.log("error getting leaderboard"));
    } else if (eventID && lBType==="t") {
      getTeamLeaderboard(eventID, timing)
      .then((data) => {
        if (data === undefined) data = null;
        setEntries(data);
      })
      .catch(() => console.log("error getting leaderboard"));
    }
  }, [eventID, lBType, timing]);

  useEffect(() => {
    let tempEntries = [];
    let tempCards = [];
    if (entries) {
      tempEntries = filterEntries(entries);

      let tempPlace = 0;
      let place = 0;
      let tempDist = 0;

      let totalDistCards = [];
      let fastPaceCards = [];
      let longRunCards = [];

      let totalDist = 0;
      let fastPace = 999999;
      let longRun = 0;

      tempEntries.forEach((e) => {
        if (e !== null) {
          if (tempDist !== e.mi) {
            tempPlace++;
            place = tempPlace;
            e.p = place;
            tempDist = e.mi;
          } else {
            tempPlace++;
            e.p = place;
          }

          if (e.mi > totalDist && lBType==="i") {
            totalDistCards = [e];
            totalDist = e.mi;
          } else if (e.mi === totalDistCards) {
            totalDistCards.push(e);
          }

          if (!isNaN(e.prP) && lBType==="i") {
            if (e.prP < fastPace) {
              fastPaceCards = [e];
              fastPace = e.prP;
            } else if (e.prP === fastPace) {
              fastPaceCards.push(e);
            }
          }

          if (!isNaN(e.prD) && lBType==="i") {
            if (e.prD > longRun) {
              longRunCards = [e];
              longRun = e.prD;
            } else if (e.prD === longRun) {
              longRunCards.push(e);
            }
          }
        }
      });

      totalDistCards.forEach((e, i) => {
        tempCards.push(
          <div className="card" key={"dc" + i}>
            <div className="card-header">Total Distance</div>
            <div className="card-data">
              <div>
                <div className="name">{e.n}</div>
                <div className="value">{e.mi} mi</div>
              </div>
            </div>
          </div>
        );
      });

      longRunCards.forEach((e, i) => {
        tempCards.push(
          <div className="card" key={"lr" + i}>
            <div className="card-header">Longest Single Distance</div>
            <div className="card-data">
              <div>
                <div className="name">{e.n}</div>
                <div className="value">{e.prDP}</div>
              </div>
            </div>
          </div>
        );
      });

      fastPaceCards.forEach((e, i) => {
        tempCards.push(
          <div className="card" key={"fp" + i}>
            <div className="card-header">Fastest Single Pace</div>
            <div className="card-data">
              <div>
                <div className="name">{e.n}</div>
                <div className="value">{e.prPP}</div>
              </div>
            </div>
          </div>
        );
      });

      setFiltered(tempEntries);
      setCards(tempCards);
    }
  }, [entries, timing, ageGroup, gender]);

  useEffect(() => {
    if (entries) {
      setFiltered(filterEntries(entries, search, gender, ageGroup));
    }
  }, [search]);

  let lb_nav = null;

  let iaClass = eventID === 403781 ? "nav-item selected" : "nav-item";
  let ilClass = eventID === 403383 ? "nav-item selected" : "nav-item";
  let mnClass = eventID === 403763 ? "nav-item selected" : "nav-item";
  let wiClass = eventID === 402986 ? "nav-item selected" : "nav-item";
  let neClass = eventID === 407133 ? "nav-item selected" : "nav-item";

  if (showMyLB === null) {
    lb_nav = (
      <div className="lb-nav">
        <div className={wiClass} onClick={() => {setEventID(402986);setLBType("i");}}>
          <span>OneWI</span>
        </div>
        <div className={mnClass} onClick={() => {setEventID(403763);setLBType("i");}}>
          <span>OneMN</span>
        </div>
        <div className={ilClass} onClick={() => {setEventID(403383);setLBType("i");}}>
          <span>OneIL</span>
        </div>
        <div className={iaClass} onClick={() => {setEventID(403781);setLBType("i");}}>
          <span>OneIA</span>
        </div>
        <div className={neClass} onClick={() => {setEventID(407133);setLBType("i");}}>
          <span>OneNE</span>
        </div>
      </div>
    );
  } else {
    let iClass = lBType==="i" ? "nav-item selected" : "nav-item";
    let tClass = lBType==="t" ? "nav-item selected" : "nav-item";
    lb_nav = (
      <div className="lb-nav">
        <div className={iClass} onClick={() => {setEventID(eventID);setLBType("i");}}>
          <span>Individuals</span>
        </div>
        <div className={tClass} onClick={() => {setEventID(eventID);setLBType("t");}}>
          <span>Teams</span>
        </div>
      </div>
    );
  }

  let rowRenderer;
  
  if(lBType==="i") {
  rowRenderer = ({ index, isScrolling, key, style }) => {
    return (
      <div key={key} style={style} className="item">
        <div>
          <div className="name">
            {filtered[index].p}. {filtered[index].fn} {filtered[index].ln}
          </div>
          <div className="meta">
            {filtered[index].g} · {filtered[index].ag} · {filtered[index].c},{" "}
            {filtered[index].st}
          </div>
          <div className="value">
            <b>{filtered[index].mi} mi</b> | {filtered[index].km} km
          </div>
        </div>
      </div>
    );
  };
} else if(lBType==="t") {
  rowRenderer = ({ index, isScrolling, key, style }) => {
    return (
      <div key={key} style={style} className="item">
        <div>
          <div className="name">
            {filtered[index].p}. {filtered[index].ln}
          </div>
          <div className="value">
            <b>{filtered[index].mi} mi</b> | {filtered[index].prc} %
          </div>
          <div className="meta">
            {filtered[index].count} members | {filtered[index].avg} mi/member
          </div>
         
        </div>
      </div>
    );
  };
}

let searchPlaceholder = lBType==="i" ? "Find a Friend" : "Find a Team";

let lbFilters = lBType==="i" ? <div className="lb-filters">
<div className="custom-select large">
  <select value={group} onChange={(e) => setGroup(e.target.value)}>
    <option value="">Everyone</option>
  </select>
</div>
<div className="custom-select">
  <select value={gender} onChange={(e) => setGender(e.target.value)}>
    <option value="">Men &amp; Women</option>
    <option value="M">Men</option>
    <option value="W">Women</option>
  </select>
</div>
<div className="custom-select">
  <select
    value={ageGroup}
    onChange={(e) => setAgeGroup(e.target.value)}
  >
    <option value="">All Ages</option>
    <option value="<20">&lt;20</option>
    <option value="20-24">20-24</option>
    <option value="25-29">25-29</option>
    <option value="30-34">30-34</option>
    <option value="35-39">35-39</option>
    <option value="40-44">40-44</option>
    <option value="45-49">45-49</option>
    <option value="50-54">50-54</option>
    <option value="55-59">55-59</option>
    <option value="60-64">60-64</option>
    <option value="65-69">65-69</option>
    <option value="70+">70+</option>
  </select>
</div>
</div> : null;

let lbLeaders = lBType==="i" ? <div className="lb-leaders">
<div className="card leadericon">
  <img src="img/leaders.svg" alt="leaders" />
</div>
{cards}
</div> : null;

  return (
    <>
      <div className="leaderboard">
        {lb_nav}
        {lbFilters}
        {lbLeaders}
        <div className="lb-list-options">
          <div className="search">
            <img src="img/search.svg" alt="search" />
            <input
              type="text"
              placeholder={searchPlaceholder}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="map"></div>
        </div>
        <WindowScroller>
          {({
            height,
            isScrolling,
            onChildScroll,
            registerChild,
            scrollTop,
          }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <div ref={registerChild}>
                  <List
                    autoHeight
                    className="lb-list"
                    height={height}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    rowCount={filtered.length}
                    rowHeight={72}
                    rowRenderer={rowRenderer}
                    scrollTop={scrollTop}
                    width={width}
                    overscanRowCount={3}
                  />
                </div>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
        ,
      </div>
    </>
  );

  function filterEntries(originalList) {
    let temp = [];
    if (originalList !== null) {
      temp = originalList.filter((e) => {
        let match = true;
        if (search !== "" && !e.n.toLowerCase().includes(search.toLowerCase()))
          match = false;
        if (gender !== "" && e.g !== gender) match = false;
        if (ageGroup !== "" && e.ag !== ageGroup) match = false;

        if (match) {
          return true;
        } else {
          return false;
        }
      });
    }

    return temp;
  }
};

export default Leaderboard;
