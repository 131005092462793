
import React, { useContext } from 'react'
import { withRouter, RouteComponentProps, Redirect, Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';

import {auth} from './firebase.js';
import { AuthContext } from "./Auth";


interface MyFormValues {
    email?: string;
    password?: string;
  }

const Password = ({history}:RouteComponentProps) => {

    const { currentUser } = useContext(AuthContext);
    if (currentUser) {
        return <Redirect to="/Log" />;
    }
	
    return (
        <div className="log">
        
        <div className="link card">
          <div className="cardHead">Reset Your Password</div>
          <div className="instructions">If you have an account regsitered with the email you provide below, you will receive an email with a link where you can set a new password.</div>
          <div className="form">
        <Formik
      initialValues={{ email: ''}}
      validate={values => {
        const errors:MyFormValues = {};
        if (!values.email) {
          errors.email = 'Email Address Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
            await auth.sendPasswordResetEmail(values.email,{url:"https://oneus.run/login"}).then(function() {
              alert("You will receive an email shortly to reset your password.");
              history.replace('/login');
            })
            .catch(function(error) {
              alert(error);
            });;
            
		} catch(error) {
            alert(error.message);
            setSubmitting(false);
		}
      }}
    >
      {({ isSubmitting }) => (
        <Form>
           <label>Email Address</label>
          <Field type="email" name="email" placeholder="Email" />
          <ErrorMessage name="email" component="div" className="error" />
          
          <button type="submit" disabled={isSubmitting}>
            Reset Password
          </button>
        </Form>
      )}
    </Formik>

    <Link to="/login" className="login-redirect">Know your password already? Go to Login.</Link>

    <Link to="/register" className="login-redirect">Don't have a OneUS.run account? Register Now!</Link>

    
    </div></div></div>
    )


}

export default withRouter(Password);
