import React from "react";

const Home = () => {
  return (
    <>
      <div className="home">
        <div className="photos">
          <div><img src="img/home-1.jpg" alt="Runner"/></div>
          <div><img src="img/home-2.jpg" alt="Runner"/></div>
          <div><img src="img/home-3.jpg" alt="Runner"/></div>
          <div><img src="img/home-4.jpg" alt="Runner"/></div>
          <div><img src="img/home-5.jpg" alt="Runner"/></div>
          <div><img src="img/home-6.jpg" alt="Runner"/></div>
          <div><img src="img/home-7.jpg" alt="Runner"/></div>
          <div><img src="img/home-8.jpg" alt="Runner"/></div>
          <div><img src="img/home-9.jpg" alt="Runner"/></div>
        </div>
        
      <div className="join">
        <h2>JOIN A CHALLENGE NOW!</h2>
        <a name="join"/>
        <div className="states">
          <a href="https://wi.oneus.run/" rel="noopener noreferrer" target="_blank"><img src="img/OneWI.png" alt="OneWI" /></a>
          <a href="https://ne.oneus.run/" rel="noopener noreferrer" target="_blank"><img src="img/OneNE.png" alt="OneNE" /></a>
          <a href="https://mn.oneus.run/" rel="noopener noreferrer" target="_blank"><img src="img/OneMN.png" alt="OneMN" /></a>
          <a href="https://il.oneus.run/" rel="noopener noreferrer" target="_blank"><img src="img/OneIL.png" alt="OneIL" /></a>
          <a href="https://ia.oneus.run/" rel="noopener noreferrer" target="_blank"><img src="img/OneIA.png" alt="OneIA" /></a>
          <img src="img/MoreStates.png" alt="More States Soon" />
        </div>
        </div>

        <div className="about">
          <p>
            Bring the spirit of the running community back into our socially
            distanced lives by joining one of the OneUS Challenges! ​​
          </p>
          <p>
            State running events and retail industries have been hit hard in
            recent months, and OneUS hopes to reenergize and uplift the running
            community. ​
          </p>
          <p>
            Challenge yourself, your family, and your friends to run this summer!
            With all summer races cancelled or postponed, this is an amazing
            opportunity to get out the door, log some miles and get recognized
            along the way.​
          </p>
          <p>
            Whether you're a seasoned runner or a newbie looking to start, join
            a challenge to log miles and level up. Don’t miss the opportunity to be a
            part of a community of runners pushing themselves and each other to
            train and give back all summer long.
          </p>
          <ul>
            <li>
              10% of every registration goes back to local running stores in
              your state. You'll be given the opportunity to list your favorite
              local running store during registration​
            </li>
            <li>
              Log Miles, Level Up - Using a custom leaderboard, participants can
              record their runs and walks to compare themselves against their
              friends and other participants​
            </li>
            <li>
              ​Runners earn virtual ‘Badges’ by achieving distance milestones
              and running streaks throughout the summer​
            </li>
            <li>
              Get your team involved! 10 or more members unlocks a discount on
              registration fees​
            </li>
            <li>
              Track your collective team miles all summer. 
            </li>
          </ul>
          ​
        </div>

        <div className="support">
        <h2>NON-PROFITS WE'RE SUPPORTING</h2>
        <div className="nonprofits">
          <div>OneWI</div>
        <a href="https://www.feedingwi.org/" rel="noopener noreferrer" target="_blank" alt=""><img src="img/nonprofits/FW.png" alt="Feeding Wisconsin" /></a>
        <a href="https://www.thistimetomorrow.org/" rel="noopener noreferrer" target="_blank" alt=""><img src="img/nonprofits/TTT.png" alt="This Time Tomorrow" /></a>
        <div>OneNE</div>
        <a href="https://www.gsfb.org/" rel="noopener noreferrer" target="_blank" alt=""><img src="img/nonprofits/GSFB.png" alt="Good Shepherd" /></a>
        <div>OneMN</div>
        <a href="https://bravelikegabe.org/" rel="noopener noreferrer" target="_blank" alt="Brave Like Gabe"><img src="img/nonprofits/BLG.png" alt="Brave Like Gabe" /></a>
        <div>OneIL</div>
        <a href="https://www.nhl.com/blackhawks/community" rel="noopener noreferrer" target="_blank" alt=""><img src="img/nonprofits/CBF.png" alt="Chicago Blackhawks Foundation" /></a>
        <div>OneIA</div>
        <a href="https://adaptivesportsiowa.org/" rel="noopener noreferrer" target="_blank" alt=""><img src="img/nonprofits/ASI.png" alt="Adaptive Sports Iowa" /></a>
        <a href="https://www.trackguyfoundation.com/" rel="noopener noreferrer" target="_blank" alt=""><img src="img/nonprofits/TGF.png" alt="Track Guy Foundation" /></a>
        
        </div>

      </div>
      </div>
    </>
  );
};

export default Home;
